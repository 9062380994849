import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import instructors from '../../data/instructors';
import InstructorPortfolioItem from "../../components/instructors/InstructorPortfolioItem";
import SectionTitle from "../../components/SectionTitle";

const generateSlug = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-');
};

const InstructorPortfolio = () => {
    // Group instructors by belt color
    const groupedInstructors = instructors.reduce((acc, instructor) => {
        const beltColor = instructor.belt || 'other'; // default to 'other' if no belt defined
        if (!acc[beltColor]) {
            acc[beltColor] = [];
        }
        acc[beltColor].push(instructor);
        return acc;
    }, {});

    return (
        <div className="section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
            <Container>
                {/* Lead Instructor Section */}
                <Row style={{marginBottom: "80px!important"}}>
                    <Col style={{marginBottom: "80px!important"}}>
                        <SectionTitle title="LEAD INSTRUCTOR" />
                    </Col>
                </Row>
                <Row style={{justifyContent: "center", marginBottom: "80px!important"}} className={'portfolio-column-four mbn-30'}>
                    <Col lg={4} md={6} style={{marginBottom: "80px!important"}} className={'mb-30'}>
                        <InstructorPortfolioItem
                            id={'lead-instructor'}
                            variant={'column'}
                            slug={generateSlug('bruno-frazatto')}
                            title={'Bruno Frazatto'}
                            thumb={'bruno-fight-stance.jpeg'} // Ensure you have the correct path to the image
                            category={'Lead Instructor'}
                        />
                    </Col>
                </Row>

                {/* Other Instructors Section */}
                {Object.keys(groupedInstructors).map(beltColor => {    
                if (beltColor === 'lead') return null;


                return (    
                    <div style={{marginBottom: "80px", marginTop: "40px" }}key={beltColor}>
                        <Row>
                            <Col style={{marginBottom: "80px!important"}}>
                                <SectionTitle
                                    title={`${beltColor.toUpperCase()} BELT INSTRUCTORS`}
                                />
                            </Col>
                        </Row>
                        <Row className={'portfolio-column-four mbn-30'}>
                            {groupedInstructors[beltColor].map(portfolio => (
                                <Col key={portfolio.id} lg={4} md={6} className={'mb-30'}>
                                    <InstructorPortfolioItem
                                        id={portfolio.id}
                                        variant={'column'}
                                        slug={generateSlug(portfolio.title)}
                                        title={portfolio.title}
                                        thumb={portfolio.thumb}
                                        category={portfolio.category}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                )}
            )}
            </Container>
        </div>
    );
};

export default InstructorPortfolio;
